
































import Vue from 'vue'
import { mapState } from 'vuex'
import ExchangeService from '@/services/exchange'

export enum ACCOUNT_STATUSES {
  ACTIVE = 'active',
  DISABLED = 'disabled'
}

type AccountBalanceInfo = {
  btc: number
  eur: number
  usdt: number
}

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },

  data(): {
    accountStatus: ACCOUNT_STATUSES
    accountBalanceInfo: AccountBalanceInfo
    loading: boolean
  } {
    return {
      accountStatus: ACCOUNT_STATUSES.ACTIVE,
      loading: false,
      accountBalanceInfo: {
        btc: 0,
        eur: 0,
        usdt: 0
      }
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair']),

    ...mapState('flagTrigger', ['orderFlag']),

    ACCOUNT_STATUSES() {
      return ACCOUNT_STATUSES
    },

    secretBalanceText(): string {
      return '****'
    }
  },

  methods: {
    async getExchangeWalletPortfolio() {
      this.loading = true

      try {
        const { data: dataRes } = await ExchangeService.getWalletPortfolio({ exchange: this.exchange })
        const customData = dataRes?.data || []
        if (customData.length > 0) {
          const existWallet = customData.filter((item) => item.metadata.currency === 'USD')[0]
          this.accountBalanceInfo.usdt = existWallet.metadata?.total.balance_usd || 0
          this.accountBalanceInfo.btc =
            (existWallet.metadata?.total.balance_usd || 0) / existWallet.metadata.reference_ccy.BTC
          this.accountBalanceInfo.eur =
            (existWallet.metadata?.total.balance_usd || 0) / existWallet.metadata.reference_ccy.EUR
        }
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.getExchangeWalletPortfolio()
  },

  watch: {
    orderFlag() {
      this.getExchangeWalletPortfolio()
    },

    exchange() {
      this.getExchangeWalletPortfolio()
    }
  }
})
